<template>
  <div class="checkBoxMainContainer" @click="check">
    <div class="checkBoxContainer">
      <div class="checkBoxFill" :class="`checkBoxFill-${id}`">
        <img src="../assets/svg/adria-check.svg" style="height: 7px; width: 7px">
      </div>
    </div>
    <div class="checkBoxText">
      {{text}}
    </div>
  </div>
</template>

<script>
import anime from 'animejs'

let currCheckboxId = 0;

export default {
  name: "AdriaCheckBox",
  props: ['value', 'text'],
  data: () => {
    return {
      id: ''
    }
  },
  beforeMount() {
    this.id = currCheckboxId++;
  },
  methods: {
    check() {
      this.$emit('valueChange', !this.value)
    },
    animateSelection() {
      anime({
        targets: `.checkBoxFill-${this.id}`,
        scale: this.value ? 1 : 0,
      })
    }
  },
  watch: {
    value(newVal) {
      this.animateSelection();
    }
  },
  mounted() {
    this.animateSelection();
  }
}
</script>

<style scoped lang="scss">
$adriaModra:#002D5F;

.checkBoxContainer {
  border: 2px solid $adriaModra;
  height: 15px;
  width: 15px;
}
.checkBoxMainContainer {
  display: flex;
  align-items: center;
}
.checkBoxText {
  font-size: 0.9rem;
  color: $adriaModra;
  margin-left: 3px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
}
.checkBoxMainContainer:hover {
  cursor: pointer;
}
.checkBoxFill {
  background-color: $adriaModra;
  height: 100%;
  width: 100%;
  transform: scale(0);
  justify-content: center;
  align-items: center;
  display: flex;
}

</style>