<template>
  <v-container :style="{background: 'none', minHeight: minHeight ? `${minHeight}px` : ''}">
    <v-row class="align-center border">
      <v-col
          v-for="(item, mainIndex) in header"
          :key="`${item.title} - ${mainIndex}`"
          :class="getClassForHeader(item)"
      >
        <div style="display: flex; flex-direction: row" :style="{ justifyContent: !item.alignLeft ? 'center' : '' }">
          <AdriaSelect
              :style="{marginLeft: '0px'}"
              v-if="item.selectable"
              :id="`adria-table-${item.key}-${item.selectableId}`"
              :size="'xs'"
              :selected-item="item.selectedItem"
              :all-items="item.items"
              :secondary="true"
              @select="(it) => handleSelect(it, item.selectableId)"
              :max-width="100"
              :custom-render="true"
              :tooltip="item.tooltip"
              :tooltip-text="item.tooltipText"
          >
            <template v-slot:item="{ currentItem }">
              <slot :name="`adria-table-${item.key}-${item.selectableId}`" v-bind:currentItem="currentItem">

              </slot>
            </template>
          </AdriaSelect>
          <span v-if="!item.selectable">
            {{ $t(item.title) }}
          </span>
        </div>
      </v-col>
    </v-row>
    <template v-if="data.length > 0">
      <v-row
          v-for="(item, index) in data"
          :class="getClassForRow(index)"
          :key="`adria-table-item-${index}`"
          :id="`${name}-adria-table-item-${index}`"
          @click="handleClick(index)"
      >
        <v-col class="pl-5 itemTextContent pt-2 pb-2"
               style="font-size: 14px; text-align: left"
               :style="{textAlign: tableRow.alignLeft ? 'left' : 'center'}"
               v-for="(tableRow, index2) in header"
               :class="`col-lg-${tableRow.cols}`"
               :key="`adria-table-item-content-${index}-${index2}`"
        >
        <span v-if="tableRow.extractor">
          {{ tableRow.extractor(item[tableRow.key]) }}
        </span>
          <span v-else>
           {{ item[tableRow.key] }}
          </span>
        </v-col>
      </v-row>
    </template>
    <NoDataSkeleton v-else/>

  </v-container>
</template>

<script>
import AdriaSelect from "@/components/AdriaSelect";
import NoDataSkeleton from "@/components/skeletons/NoDataSkeleton";

export default {
  name: "AdriaTable",
  components: {NoDataSkeleton, AdriaSelect},
  props: ['header', 'data', 'clickable', 'name', 'chosenItemIndex', 'minHeight', 'hover'],
  data: () => {
    return {
      selectedIndex: -1
    }
  },
  methods: {
    // Handles click on clickable item
    handleClick(index) {
      if (!this.clickable) return
      if (this.selectedIndex === index) {
        this.selectedIndex = -1;
      } else {
        this.selectedIndex = index;
      }
      this.$emit('onSelectItem', this.selectedIndex > -1 ? this.data[this.selectedIndex] : undefined)
    },
    getClassForRow(index) {
      let classes = [];
      if (index === this.data.length - 1) {
        classes.push('bordered');
      }
      if (index === this.selectedIndex) {
        classes.push('selected')
      }
      if (this.clickable || this.hover) {
        classes.push('clickable');
      }
      if (this.hover) {
        classes.push('info-hover')
      }

      return classes.join(" ");
    },
    getClassForHeader(item) {
      const center = item.alignLeft ? '' : 'align-center text-center'
      if (item.selectable) {
        return `${center} pl-5 itemText pt-2 pb-2 col-lg-${item.cols}`
      } else {
        return `${center} pl-5 itemText pt-2 pb-2 col-lg-${item.cols}`
      }
    },
    handleSelect(item, selectableId) {
      this.$emit('select', {
        item,
        selectableId
      })
    }
  },
  watch: {
    chosenItemIndex(it) {
      this.selectedIndex = it;
    }
  }
}
</script>

<style scoped lang="scss">
.itemText {
  font-weight: 600;
  color: #5A5A5A;
  font-size: 14px;
}

.border {
  border-top: 1px solid rgba(90, 90, 90, 0.4);
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}

.bordered {
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}

.clickable {

}

.clickable:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);

  .itemTextContent {
    color: rgba(0, 45, 95, 1.0);
  }
}

.selected {
  background-color: rgba(0, 0, 0, 0.05);

  .itemTextContent {
    color: rgba(0, 45, 95, 1.0);
    font-weight: bold;
  }
}

.itemTextContent {
  color: #5A5A5A;
  font-size: 14px;
}

.info-hover:hover {
  cursor: auto;
}
</style>
