<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small>
        mdi-information
      </v-icon>
    </template>
    <span v-if="textLines.length <= 1">{{this.$props.text}}</span>
    <ul v-else>
      {{textLines[0]}}
      <li v-for="(line, index) in textLines.slice(1, textLines.length)" :key="`${index}-${line}`">
        {{line}}
      </li>
    </ul>
  </v-tooltip>
</template>

<script>
export default {
  name: "AdriaInfoTooltip",
  props: ['text'],
  computed: {
    textLines() {
      return this.text.split("\n")
    }
  }
}
</script>

<style scoped>

</style>
