<template>
  <div class="mapNonClusterContainer">
    <div id="myMap" ref="myMap" class="myMap">

    </div>
    <div class="legendContainer" ref="legendContainer">
      <div class="collapseContainer">
        <v-btn
            v-if="false"
            class="collapseIconContainer"
            ref="collapseIconContainer"
            @click="filterShown=!filterShown"
            icon
            x-small
        >
          <v-icon>
            mdi-arrow-down-thick
          </v-icon>
        </v-btn>
      </div>
      <AdriaMachMapLegend
          :filter="filterConfig"
          @filterChange="handleFilterChange"
          @onProductChange="handleProductChange"
          :activeCount="activeCount"
          :inactiveCount="inactiveCount"
          :all-products="allItems"
          :selected-product="selectedItem"
      />
    </div>
  </div>
</template>

<script>
import mapboxgl, {LngLat} from "mapbox-gl";
import {isTimestampSeconds, padTo2Digits} from "@/utils/date";
import AdriaMachMapLegend from "@/components/AdriaMachMapLegend";
import WebsocketManager from "@/utils/socketManager";
import store from "@/store";
import eventBus, {BusEvents} from "@/eventBus";
import anime from "animejs";
import httpClient from "@/utils/http/httpClient";

export default {
  name: "MapNonCluster",
  components: {AdriaMachMapLegend},
  async mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

    const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/vehicles/types`)
    this.allVehicleTypes = res.data
    this.filterSelection()

    setInterval(() => {
      this.blip();
    }, 5000)

    this.map = new mapboxgl.Map({
      container: document.getElementById('myMap') || 'myMap',
      style: "mapbox://styles/mapbox/light-v10",
      center: this.center,
      zoom: 5,
    });

    this.map.on('load', () => {
      this.loaded = true
    });

    store.watch(
        state => state.auth.authToken,
        (newVal) => {
          this.websocket.refreshToken(newVal);
        }
    );

    eventBus.$on(BusEvents.SOCKET_MSG_EVENT, (event) => {
      try {
        const data = JSON.parse(event.data)
        switch (data.messageId) {
          case "ACTIVITY_UPDATE":
            this.handleActivityUpdate(data.payload)
            break
          case "LOCATION_UPDATE":
            // console.log('location update')
            this.handleLocationUpdate(data.payload)
            break
          default:
            // console.log(data)
            console.log("Unknown format")
        }

      } catch (e) {
        console.log('Error while parsing socket message', event.data)
      }
    });

    this.websocket.refreshToken(this.authToken);
    this.toggleFilter(this.filterShown)
  },
  props: {
    markers: {
      type: Array,
      required: true
    },
    activeMachs: {
      type: Object,
      required: true
    },
    inactiveMachs: {
      type: Object,
      required: true
    },
    vehicles: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      map: '',
      center: new LngLat(14.505751, 46.056946),
      loaded: false,
      allowedIds: [
        'GPS_LATITUDE',
        'GPS_LONGITUDE',
        'LIVING_MEASURED_TEMPERATURE',
        'MAINS_SUPPLY_VALUE'
      ],
      markersDrawn: false,
      filterConfig: {
        active: true,
        inactive: true,
        blip: true,
        van: true,
        caravan: true,
        motorhome: true,
        unknown: true,
        dayNum: 2,
      },
      websocket: new WebsocketManager(),
      authToken: this.$store.getters.getAuthToken,
      activeVehicles: this.$props.activeMachs,
      inactiveVehicles: this.$props.inactiveMachs,
      activeFeatures: [],
      inactiveFeatures: [],
      machBlipsBuffer: [],
      filterShown: true,
      allVehicleTypes: [],
      allItems: [],
      selectedItem: undefined
    }
  },
  computed: {
    activeCount: function () {
      const allVehicles = Object.values(this.vehicles)
      const filteredVehicles = allVehicles
          .filter((v) => {
            const model = v && v.model ? v.model.split(' ').length > 0 ? v.model.split(' ')[0].toLowerCase() : undefined : undefined
            const isModelIncluded = this.selectedItem.value === '' || this.selectedItem.value.toLowerCase() === model
            return v && this.filterConfig[v.type] && this.activeMachs[v.machId] && (this.selectedItem) && isModelIncluded
          })
      return filteredVehicles.length;
    },
    inactiveCount: function () {
      const allVehicles = Object.values(this.vehicles)
      const filteredVehicles = allVehicles
          .filter((v) => {
            const model = v && v.model ? v.model.split(' ').length > 0 ? v.model.split(' ')[0].toLowerCase() : undefined : undefined
            const isModelIncluded = this.selectedItem.value === '' || this.selectedItem.value.toLowerCase() === model
            return v && this.filterConfig[v.type] && this.inactiveMachs[v.machId] && isModelIncluded
          })
      return filteredVehicles.length;
    }
  },
  watch: {
    markers: function (newVal) {
      if (newVal && newVal.length > 0 && this.loaded) {
        this.drawMarkers()
      }
    },
    loaded: function (newVal) {
      if (newVal && this.markers && this.markers.length > 0) {
        this.drawMarkers();
      }
    },
    filterShown: function (newVal) {
      this.toggleFilter(newVal)
    },
    activeMachs: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.activeVehicles = newVal;
      }
    },
    inactiveMachs: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.inactiveVehicles = newVal;
      }
    }
  },
  methods: {
    filterSelection() {
      // console.log(this.allVehicleTypes, 'types')
      this.allItems = this.allVehicleTypes.filter(it => this.filterConfig[it.type.toLowerCase()]).map((it) => {
        return {
          displayName: `${it.name}`,
          value: it.name,
          count: it.count
        }
      }).sort((a,b) => {
        let leftValue = a.count
        let rightValue = b.count
        return rightValue - leftValue
      })

      this.allItems.unshift({
        displayName: this.$t('All vehicles').toString(),
        value: ''
      })

      this.selectedItem = this.allItems[0]
    },
    toggleFilter(newVal) {
      const toValue = !newVal ? (this.$refs.legendContainer.offsetHeight - this.$refs.collapseIconContainer.offsetHeight) : 0;
      anime({
        targets: '.legendContainer',
        translateY: toValue,
      })
    },
    drawMarkers() {
      if (this.markersDrawn) {
        // update markers
        return
      }
      this.map.addImage('pulsing-dot', this.getPulsingDot(), {pixelRatio: 2});
      this.addMarkersToMap(true, this.filterConfig.van, this.filterConfig.motorhome, this.filterConfig.caravan);
      this.addMarkersToMap(false, this.filterConfig.van, this.filterConfig.motorhome, this.filterConfig.caravan);
      this.map.on('click', 'points-active', async (e) => {
        await this.handleMapClick(e);
      });
      this.map.on('click', 'points-inactive', async (e) => {
        await this.handleMapClick(e);
      });
      this.map.on('mouseenter', 'points-active', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseenter', 'points-inactive', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'points-active', () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('mouseleave', 'points-inactive', () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.markersDrawn = true
    },
    blip() {
      if (this.map.getLayer('points-blink')) {
        // console.log('No blip layer, returning')
        return
      }

      if (this.filterConfig.blip) {
        this.map.addLayer({
          'id': 'points-blink',
          'type': 'symbol',
          'source': 'points-active',
          'layout': {
            'icon-image': 'pulsing-dot'
          },
          filter: ['in', 'machId', ...this.machBlipsBuffer]
        })
      }
      this.machBlipsBuffer = [];

      if (this.filterConfig.blip) {
        setTimeout(() => {
          if (this.map.getLayer('points-blink') != null) {
             this.map.removeLayer('points-blink');
          }
        }, 1700)
      }
    },
    convertIdToString(valueId) {
      switch (valueId) {
        case 'GPS_LATITUDE':
          return this.$t('Latitude')
        case 'GPS_LONGITUDE':
          return this.$t('Longitude')
        case 'LIVING_MEASURED_TEMPERATURE':
          return this.$t('Vehicle interior temperature')
        case 'MAINS_SUPPLY_VALUE':
          return this.$t('Connection to 220V')
      }
    },
    getValueIdUnit(valueId) {
      switch (valueId) {
        case 'GPS_LATITUDE':
          return ''
        case 'GPS_LONGITUDE':
          return ''
        case 'LIVING_MEASURED_TEMPERATURE':
          return 'C'
        case 'MAINS_SUPPLY_VALUE':
          return ''
      }
    },
    async handleMapClick(e) {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const timestamp = e.features[0].properties.timestamp;
      const activityTimestamp = e.features[0].properties.activityTimestamp
      const dateLocation = new Date(isTimestampSeconds(timestamp) ? timestamp * 1000 : timestamp)
      const dateActivity = activityTimestamp ? new Date(activityTimestamp) : null

      const getHours = (date) => {
        return [
          padTo2Digits(date.getHours()),
          padTo2Digits(date.getMinutes()),
          padTo2Digits(date.getSeconds())
        ].join(':')
      }

      const getDate = (date) => {
        return [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/')
      }

      const machId = e.features[0].properties.machId

      const popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
              `${this.$t('Fetching data for Mach:')} ${machId}`
          )
          .addTo(this.map);

      try {
        const data = (await httpClient.get(`${process.env.VUE_APP_API_URL}/vehicles/${machId}/basicInfo`)).data
        if (!popup.isOpen()) {
          return
        }
        popup.remove()
        const chassis = data.chassis ? data.chassis : this.$t('Unknown')
        const engine = data.engine ? data.engine : this.$t('Unknown')
        // console.log(data)
        const sensorsHTML = data.latestValues.filter((it) => this.allowedIds.find((it2 => it2 === it.valueId))).map((it) => {
          return `<b>${this.convertIdToString(it.valueId)}: </b>${it.measuredValue} ${this.getValueIdUnit(it.valueId)}<br>`
        }).join('')
        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(
                `<div>
                    <b style="font-size: 0.9rem; margin-bottom: 15px">${this.$t('Basic vehicle information')}</b><br>
                    <b>${this.$t('MachID')} </b>${data.vehicle ? data.vehicle.machId : this.$t('Unknown')} <br>
                    <b>${this.$t('Location updated')} </b> ${getHours(dateLocation)} ${getDate(dateLocation)}<br>
                    <b>${this.$t('Activity updated')}</b> ${dateActivity ? getHours(dateActivity) : ''} ${dateActivity ? getDate(dateActivity) : ''} <br>
                    <b>${this.$t('Model')} </b>${data.vehicle ? data.vehicle.model : this.$t('Unknown')} <br>
                    <b>${this.$t('Vehicle type')} </b>${data.vehicle ? data.vehicle.type : this.$t('Unknown')}<br>
                    <hr>
                    <b style="font-size: 0.9rem; margin-bottom: 15px; margin-top:15px">${this.$t('Last measured values in the vehicle')}</b><br>
                    ${sensorsHTML}
                   </div>
                  `
            )
            .addTo(this.map);
      } catch (e) {
        if (!popup.isOpen()) {
          return
        }
        popup.remove()
        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(this.$t('There was an error retrieving the data'))
            .addTo(this.map)
      }
    },
    getPulsingDot() {
      const map = this.map;
      const size = 100;
      return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1500;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width * 2, this.height * 2);
          context.beginPath();
          context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
          );
          context.fillStyle = `rgba(0,128,0, ${0.7 - t})`;
          context.fill();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height
          ).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();

          // Return `true` to let the map know that the image was updated.
          return true;
        }
      };
    },
    addMarkersToMap(activity, van, motorhome, caravan, unknown) {
      this.map.addSource(`points-${activity ? 'active' : 'inactive'}`, {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': this.getPoints(activity, van, motorhome, caravan, unknown)
        }
      });
      this.map.addLayer({
        'id': `points-${activity ? 'active' : 'inactive'}`,
        'type': 'circle',
        'source': `points-${activity ? 'active' : 'inactive'}`,
        'paint': {
          'circle-radius': 3,
          'circle-color': activity ? 'rgb(0,128,0)' : 'red',
          'circle-opacity': 1.0
        }
      })
    },
    getPoints(activity, van, motorhome, caravan, unknown) {
      const typeMap = {
        "van": van,
        "motorhome": motorhome,
        "caravan": caravan,
        'unknown': unknown
      }

      const features = this.$props.markers.filter(it => {
        const veh = this.vehicles[it.machId]
        const model = veh && veh.model ? veh.model.split(' ').length > 0 ? veh.model.split(' ')[0].toLowerCase() : undefined : undefined
        const isModelIncluded = this.selectedItem.value === '' || this.selectedItem.value.toLowerCase() === model
        return (activity ? this.activeVehicles[it.machId] : this.inactiveVehicles[it.machId]) &&
            (it.longitude !== 0 && it.latitude !== 0) && (veh ? typeMap[veh.type] : false) && isModelIncluded
      }).map((it, index) => {
        const feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              it.longitude,
              it.latitude,
              0.0
            ]
          },
          properties: {
            machId: (() => {
              return it.machId;
            })(),
            timestamp: it.timestamp,
            activityTimestamp: it.activityTimestamp,
            type: this.vehicles[it.machId] ? this.vehicles[it.machId].type : null
          }
        };
        return feature;
      })

      activity ? this.activeFeatures = features : this.inactiveFeatures = features;
      return features;
    },
    handleFilterChange(newFilter) {
      this.filterConfig = newFilter;
      this.filterSelection()
      this.applyFilterChanges()
    },
    applyFilterChanges() {
      let map = this.map;

      let removeBlinkLayer = function () {
        let blinkLayer = map.getLayer('points-blink');
        if (blinkLayer != null) { map.removeLayer('points-blink') }
      }

      if (map.getLayer('points-active') && map.getSource('points-active')) {
        removeBlinkLayer()
        map.removeLayer('points-active');
        map.removeSource('points-active');
      }
      if (map.getLayer('points-inactive') && map.getSource('points-inactive')) {
        removeBlinkLayer()
        map.removeLayer('points-inactive');
        map.removeSource('points-inactive');
      }
      if (this.filterConfig.active) {
        this.addMarkersToMap(true, this.filterConfig.van, this.filterConfig.motorhome, this.filterConfig.caravan, this.filterConfig.unknown);
      }
      if (this.filterConfig.inactive) {
        this.addMarkersToMap(false, this.filterConfig.van, this.filterConfig.motorhome, this.filterConfig.caravan, this.filterConfig.unknown);
      }
    },
    handleActivityUpdate(payload) {
      // Check if mach is among already active machs
      const alreadyActive = this.activeVehicles[payload.machId];
      // console.log(`Checking mach: ${payload.machId} - ${payload}`)
      // console.log(alreadyActive, 'already active')
      if (!alreadyActive) {
        // Move marker from inactive to active
        const mach = this.inactiveVehicles[payload.machId]
        // console.log('Match', mach, this.inactiveVehicles)
        if (!mach) return

        this.inactiveVehicles[payload.machId] = false;
        this.activeVehicles[payload.machId] = mach;

        const feature = this.inactiveFeatures.find((it) => it.properties.machId === payload.machId)
        this.inactiveFeatures = this.inactiveFeatures.filter((it) => it.properties.machId !== payload.machId)

        // console.log(feature, 'feature')
        this.activeFeatures.push(feature)

        const activeSource = this.map.getSource('points-active');
        const inactiveSource = this.map.getSource('points-inactive');

        if (activeSource) {
          // console.log('setting active features')
          activeSource.setData({
            'type': 'FeatureCollection',
            'features': this.activeFeatures
          });
        }
        if (inactiveSource) {
          // console.log('setting inactive features')
          inactiveSource.setData({
            'type': 'FeatureCollection',
            'features': this.inactiveFeatures
          });
        }
      }
      this.machBlipsBuffer.push(payload.machId);
    },
    handleLocationUpdate(payload) {
      if (payload.latitude === 0 && payload.longitude === 0) return

      const machId = payload.machId
      const isVehicleActive = this.activeVehicles[payload.machId];
      if (!isVehicleActive) {
        this.activeVehicles[payload.machId] = this.inactiveVehicles[payload.machId];
        this.inactiveVehicles[payload.machId] = false;
        this.handleActivityUpdate({machId: payload.machId})
      }

      this.activeFeatures = this.activeFeatures.map((it) => {
        if (it.properties.machId === machId) {
          return {
            ...it,
            geometry: {
              ...it.geometry,
              coordinates: [
                payload.longitude,
                payload.latitude,
                0.0
              ]
            }
          }
        } else {
          return it;
        }
      });

      const activeSource = this.map.getSource('points-active');
      if (activeSource) {
        activeSource.setData({
          type: 'FeatureCollection',
          features: this.activeFeatures
        })
      }
    },
    handleProductChange(selectedItem) {
      this.selectedItem = selectedItem
      this.applyFilterChanges()
    }
  },
  beforeDestroy() {
    this.websocket.close();
  }
}
</script>

<style scoped>
svg {
  pointer-events: none;
}

#myMap {
  height: 98vh;
  width: 100vw;
}

.legendContainer {
  position: absolute;
  padding: 20px;
  background-color: white;
  z-index: 99;
  bottom: 0;
}

.collapseContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.collapseContainer:hover {
  cursor: pointer;
  background-color: red;
}

.collapseIconContainer {
  padding: 10px;
  border: 1px solid red;
}

.collapseIconContainer:hover {
  cursor: pointer;
}

.collapseIcon:hover {
  cursor: pointer;
}

.mapNonClusterContainer {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}
</style>
