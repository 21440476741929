<template>
  <div class="mainContainer">
    <div class="headerContainer">
      <div style="color: #002D5F; font-weight: 400">
        {{$t('Filter')}}:
      </div>
      <div class="infoContainer">
        <v-dialog v-model="dialog" max-width="400">
          <template v-slot:activator="{ on, attrs}">
            <v-btn
                icon
                color="#002D5F"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>
                mdi-information
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5" style="color: #002D5F">
              {{$t('Legend')}}
            </v-card-title>
            <v-card-text>
              {{$t('Legend description')}}
            </v-card-text>
            <v-divider style="width: 90%; margin-left: auto; margin-right: auto;"/>

            <v-card-subtitle style="color: #002D5F" class="text-h6 mt-5">
              {{$t('Map items')}}
            </v-card-subtitle>
            <div class="legends ml-5 mr-5 mb-5">
              <div class="legendItem ml-2">
                <div class="activeItem it" style="background-color: rgb(0,128,0); width: 13px">

                </div>
                <div class="itText ml-2">
                  {{$t('Active vehicle description')}}
                </div>
              </div>

              <div class="legendItem ml-2">
                <div class="activeItem it">
                  <PulsingDot/>
                </div>
                <div class="itText ml-2">
                  {{$t('Active blip vehicle')}}
                </div>
              </div>

              <div class="legendItem ml-2">
                <div class="activeItem it" style="background-color: red; width: 13px">

                </div>
                <div class="itText ml-2">
                  {{$t('Inactive vehicle')}}
                </div>
              </div>
            </div>
            <v-divider style="width: 90%; margin-left: auto; margin-right: auto;"/>
            <v-card-subtitle style="color: #002D5F" class="text-h6 mt-5">
              {{$t('Filter')}}
            </v-card-subtitle>
            <p class="ml-6 mr-5" style="color: #002D5F">
              {{$t('Activity')}}
            </p>
            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <AdriaCheckBox
                    :value="filter.active"
                    :text="$t('Active vehicles')"
                />
                <div class="itText ml-4">
                  {{$t('Active vehicles filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <AdriaCheckBox
                    :value="filter.inactive"
                    :text="$t('Inactive vehicles')"
                />
                <div class="itText ml-4">
                  {{$t('Inactive vehicles filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.blip"
                      :text="$t('Activity live')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Activity liver description')}}
                </div>
              </div>
            </div>

            <v-divider style="width: 90%; margin-left: auto; margin-right: auto;"/>

            <p class="ml-6 mr-5 mt-5" style="color: #002D5F">
              {{$t('Vehicle Type')}}
            </p>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.van"
                      :text="$t('Van')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Van filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.caravan"
                      :text="$t('Caravan')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Caravan filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.motorhome"
                      :text="$t('Motorhome')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Motorhome filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.unknown"
                      :text="$t('Unknown')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Unknown filter description')}}
                </div>
              </div>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="#002D5F"
                  text
                  @click="dialog = false"
              >
                {{$t('Close')}}
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>
      </div>
    </div>
    <div>
      <div>
        <AdriaSelect
            class="mt-2"
            id="adriaProductSelect"
            size="xl"
            :no-height-limit="false"
            :all-items="allProducts"
            :selected-item="selectedProduct"
            :placeholder="$t('Products').toString()"
            @select="(it) => $emit('onProductChange', it)"
        />
      </div>
    </div>
    <div class="adriaCheckBoxContainer mt-4">
      <div>
        <p style="color: #002D5F; font-weight: 600">
          {{$t('Activity')}}
        </p>
        <div>
          <AdriaCheckBox
              key="active_vehicles_filter"
              :value="filter.active"
              :text="$t('Active vehicles') + ` (${activeCount}/${activeCount + inactiveCount})`"
              @valueChange="(it) => $emit('filterChange', {...filter, active: it})"
          />
          <AdriaCheckBox
              key="inactive_vehicles_key"
              :value="filter.inactive"
              :text="$t('Inactive vehicles') + ` (${inactiveCount}/${activeCount + inactiveCount})`"
              @valueChange="(it) => $emit('filterChange', {...filter, inactive: it})"
          />
          <AdriaCheckBox
              key="blip_key"
              :value="filter.blip"
              @valueChange="(it) => $emit('filterChange', {...filter, blip:it})"
              :text="$t('Activity live')"
          />
        </div>
      </div>
      <div>
        <p style="color: #002D5F; font-weight: 600">
          {{$t('Vehicle Type')}}
        </p>
        <div>
          <AdriaCheckBox
              key="van"
              :value="filter.van"
              :text="$t('Van')"
              @valueChange="(it) => $emit('filterChange', {...filter, van: it})"
          />
          <AdriaCheckBox
              key="caravan"
              :value="filter.caravan"
              :text="$t('Caravan')"
              @valueChange="(it) => $emit('filterChange', {...filter, caravan: it})"
          />
          <AdriaCheckBox
              key="motorhome"
              :value="filter.motorhome"
              :text="$t('Motorhome')"
              @valueChange="(it) => $emit('filterChange', {...filter, motorhome: it})"
          />
          <AdriaCheckBox
              key="unknown"
              :value="filter.unknown"
              :text="$t('Unknown')"
              @valueChange="(it) => $emit('filterChange', {...filter, unknown: it})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdriaCheckBox from "@/components/AdriaCheckBox";
import PulsingDot from "@/components/PulsingDot";
import AdriaSelect from "@/components/AdriaSelect.vue";

export default {
  name: "AdriaMachMapLegend",
  components: {AdriaSelect, PulsingDot, AdriaCheckBox},
  data: () => {
    return {
      dialog: false,
      slider: 0
    }
  },
  props: ['filter', 'activeCount', 'inactiveCount', 'vanCount', 'caravanCount', 'motorhomeCount', 'selectedProduct', 'allProducts'],
  methods: {
    onFilterChange2(it) {
      this.$emit('filterChange', {...this.filter, inactive: it})
    }
  },
  computed: {
    totalCount: function () {
      return this.activeCount + this.inactiveCount
    }
  }
}
</script>

<style scoped>
.adriaCheckBoxContainer {
  display: flex;
  gap: 100px;
}

.headerContainer {
  position: relative;
}

.infoContainer {
  position: absolute;
  top: -15px;
  right: -15px
}

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legendItem2 {
  display: flex;
  flex-direction: column;
}

.it {
  height: 10px;
  width: 10px;
  padding: 0;
  margin: 0;
  border-radius: 20px;
}

.itText {
  color: rgb(0, 0, 0, 0.5);
  font-size: 0.9rem;
}
</style>
